

export class UrlConstants {
  // static CURRENTDOMAINURL = "https://prpsms.biz";
      //LOCAL
      // static MBS_APIACCOUNTMASTER = 'https://localhost:44330/api/';
      // static MBS_APISMSMASTER = 'https://localhost:44330/api/';
      // static MBS_APITRANSACTION = 'https://localhost:44330/api/';
      // static MBS_IMGPATH = 'https://localhost:44330';

      //TEST
      // static MBS_APIACCOUNTMASTER = 'http://161.97.169.18:8081/api/';
      // static MBS_APISMSMASTER = 'http://161.97.169.18:8081/api/';
      // static MBS_APITRANSACTION = 'http://161.97.169.18:8081/api/';
      // static MBS_IMGPATH = 'http://161.97.169.18:8081';

      //LIVE
      //  static MBS_APIACCOUNTMASTER = 'http://164.52.196.49:8091/api/';
      //  static MBS_APISMSMASTER = 'http://164.52.196.49:8091/api/';
      //  static MBS_APITRANSACTION = 'http://164.52.196.49:8091/api/';
      //  static MBS_IMGPATH = 'http://164.52.196.49:8091';
       static MBS_APIACCOUNTMASTER = 'https://api.prpsms.biz/api/';
       static MBS_APISMSMASTER = 'https://api.prpsms.biz/api/';
       static MBS_APITRANSACTION = 'https://api.prpsms.biz/api/';
      static MBS_IMGPATH = 'https://api.prpsms.biz';

      //New
      // static MBS_APIACCOUNTMASTER = 'http://139.5.190.30/api/';
      // static MBS_APISMSMASTER = 'http://139.5.190.30/api/';
      // static MBS_APITRANSACTION = 'http://139.5.190.30/api/';
      // static MBS_IMGPATH = 'http://139.5.190.30';

      // static MBS_APIACCOUNTMASTER = 'http://139.5.190.30:8081/api/';
      // static MBS_APISMSMASTER = 'http://139.5.190.30:8081/api/';
      // static MBS_APITRANSACTION = 'http://139.5.190.30:8081/api/';
      // static MBS_IMGPATH = 'http://139.5.190.30';
       
       
      //   static MBS_APIACCOUNTMASTER = 'http://173.212.244.21:8032/api/';
      // static MBS_APISMSMASTER = 'http://173.212.244.21:8032/api/';
      // static MBS_APITRANSACTION = 'http://173.212.244.21:8032/api/';

      // static MBS_APIACCOUNTMASTER = 'http://173.212.244.21:8031/api/';
      // static MBS_APISMSMASTER = 'http://173.212.244.21:8031/api/';
      // static MBS_APITRANSACTION = 'http://173.212.244.21:8031/api/';

      // static MBS_APIACCOUNTMASTER = 'https://173.212.244.21:444/api/';
      // static MBS_APISMSMASTER = 'https://173.212.244.21:444/api/';
      // static MBS_APITRANSACTION = 'https://173.212.244.21:444/api/';


      

      // static MBS_APIACCOUNTMASTER = 'http://103.205.65.110:8080/mbs-account-master/';
      // static MBS_APISMSMASTER='http://103.205.65.110:8080/mbs-sms-master/';
      // static MBS_APITRANSACTION='http://103.205.65.110:8080/mbs-sms-transaction/';


      // static MBS_APIACCOUNTMASTER = 'http://ss.mpstechnologies.com/scholarlystats_alpha/services/';


        // static MBS_APIACCOUNTMASTER = 'http://10.50.8.220/scholarlystats_alpha/services/';
       // scholarlystats_alpha/services/
      // static THIRD = UrlConstants.MBS_APIACCOUNTMASTER + `platformReport/getPlatformSpecificReports`;

      // "COUNTER &
      // Custom Reports"	Reporting Period
      //       Get Available Reports
      //       Download Report
      //       Get Chart


      /* **************  USER DETAILS  *************** */
      static FORGETPASSWORD =UrlConstants.MBS_APIACCOUNTMASTER + 'Login/ForgotPassword';
      static URLRegister= UrlConstants.MBS_APIACCOUNTMASTER + 'user/RegisterUser';
      static URLRegisterCommonUser= UrlConstants.MBS_APIACCOUNTMASTER + 'user/RegisterCommonUser';
     static LOGIN = UrlConstants.MBS_APIACCOUNTMASTER + 'Login';
     static SERVICETYPE = UrlConstants.MBS_APIACCOUNTMASTER + 'common/getservicetype';
     static ACCOUNTSERVICETYPE = UrlConstants.MBS_APIACCOUNTMASTER + 'account/getservicetype';
     static ACCOUNTS =  UrlConstants.MBS_APIACCOUNTMASTER + 'account/getaccounts';

     

     static ROLEMENU =  UrlConstants.MBS_APIACCOUNTMASTER +'Role/getuserrolemenu';
     static ACCOUNTLIST = UrlConstants.MBS_APIACCOUNTMASTER +'user/GetAllUsers';
    // static ` ` UrlConstants.MBS_APIACCOUNTMASTER +'common/getaccounttype';
     static ACCOUNTMANAGER =  UrlConstants.MBS_APIACCOUNTMASTER +'common/getaccountmanager';
     static GATEWAYLISTURL =  UrlConstants.MBS_APIACCOUNTMASTER +'common/getGateWayList'; 

     static GETACCOUNTLIST =  UrlConstants.MBS_APIACCOUNTMASTER +'user/GetAccountList';   
     
     static BALANCESTATUS =  UrlConstants.MBS_APIACCOUNTMASTER +'BitCoin/GetbalanceStatus';
     static GETACCOUNTTYPELIST=  UrlConstants.MBS_APIACCOUNTMASTER +'common/getaccounttypeList';
     static GETROLELIST=  UrlConstants.MBS_APIACCOUNTMASTER +'common/getrolelist';
     static COUNTRY =  UrlConstants.MBS_APIACCOUNTMASTER +'common/getcountry';
     static STATES = UrlConstants.MBS_APIACCOUNTMASTER +'common/getstates';
     static ACCOUNTINFOBYID = UrlConstants.MBS_APIACCOUNTMASTER +'user/GetUserById';
     static ACCOUNTINFOADD = UrlConstants.MBS_APIACCOUNTMASTER +'User/SaveUser';
     static ACCOUNTSTATUSCHANGE = UrlConstants.MBS_APIACCOUNTMASTER +'User/StatusChangeUser';
     static PAYLIST =  UrlConstants.MBS_APIACCOUNTMASTER +'common/GetPaymentList';
     static SMSCRTRLIST =  UrlConstants.MBS_APIACCOUNTMASTER +'common/getSmsCrTrDetails';
     static PAYSTATUS =  UrlConstants.MBS_APIACCOUNTMASTER +'BitCoin/Getpaymentstatus';
     
     static GROUPLIST =  UrlConstants.MBS_APIACCOUNTMASTER +'common/GetGroupList';
     static GROUPCHANGESTATUS =  UrlConstants.MBS_APIACCOUNTMASTER +'common/Groupchangestatus';
     static GROUPADD =  UrlConstants.MBS_APIACCOUNTMASTER +'common/AddUpdateGroup';

     static  DIRECTORYLIST = UrlConstants.MBS_APIACCOUNTMASTER +'common/GetPhoneList';
     static  DIRECTORYBYID = UrlConstants.MBS_APIACCOUNTMASTER +'common/GetPhoneById';
     static  DIRECTORYADD = UrlConstants.MBS_APIACCOUNTMASTER +'common/AddUpdatePhone';
     static  DIRECTORYDELETE = UrlConstants.MBS_APIACCOUNTMASTER +'common/StatusChangePhone';
     static  DIRECTORYUPLOAD = UrlConstants.MBS_APIACCOUNTMASTER +'accountdirectory/upload';
     static  DIRECTORYREMOVE = UrlConstants.MBS_APIACCOUNTMASTER +'accountdirectory/remove';
     static  LANGUAGE = UrlConstants.MBS_APIACCOUNTMASTER + 'common/getlanguage';
     static PHONEBOOKLIST=UrlConstants.MBS_APIACCOUNTMASTER+'accountgroupdirectory/list';
     static PHONEBOOKGROUPDELETE=UrlConstants.MBS_APIACCOUNTMASTER+'common/StatusChangePhoneGroup';

     static  SENDERBYACCUNTAMDSERVICETYPE =  UrlConstants.MBS_APISMSMASTER +'sender/getbyaccountandservicetype';
     static  SMSROUTETYPEBYSERVICETYPE = UrlConstants.MBS_APISMSMASTER +'common/getsmsroutetypebyservicetype';


    //  static   SENDERLIST = UrlConstants.MBS_APILOCAL +'common/UserSenderIdGet';
    //  static   SENDERADD = UrlConstants.MBS_APILOCAL +'common/SmsSenderIdAddUpdate';
    //  static   SENDERCHANGESTATUS = UrlConstants.MBS_APILOCAL +'common/SenderIdStatusChange';

     static  SENDERLIST = UrlConstants.MBS_APISMSMASTER +'common/getusermasking';
     static  GETSENDERLIST = UrlConstants.MBS_APISMSMASTER +'common/getUserMaskingList';
     static  SENDERADD =  UrlConstants.MBS_APISMSMASTER +'common/AddUpdateUserMasking';
     static  SENDERBULKADD =  UrlConstants.MBS_APISMSMASTER +'common/SmsSenderIdBulkUpload';
     static   SENDERCHANGESTATUS =  UrlConstants.MBS_APISMSMASTER +'common/changestatus';
     static SENDERBYSENDERID = UrlConstants.MBS_APISMSMASTER +'common/getmaskingbyid';
     static SENDERUNIQUECHECK = UrlConstants.MBS_APISMSMASTER + 'common/maskingUniqueCheck'

     static ADDUSERGATEWAYCOUNTRYMAP=UrlConstants.MBS_APISMSMASTER +'common/addUserGateWayMap';
     static ADDGATEWAY=UrlConstants.MBS_APISMSMASTER +'common/addGateWay';
     static ADDGATEWAYCOUNTRYMAPPING=UrlConstants.MBS_APISMSMASTER +'common/addsmscCountry';
     static USERGATEWAYCOUNTRYMAPPINGLIST=UrlConstants.MBS_APISMSMASTER +'common/getUsersmscList';
     static GATEWAYCOUNTRYMAPPINGLIST=UrlConstants.MBS_APISMSMASTER +'common/getsmscCountryList';

      static   TEMPLATELIST = UrlConstants.MBS_APISMSMASTER +'common/GetSMSTemplateLists';
      static   TEMPLATELISTDDL = UrlConstants.MBS_APISMSMASTER +'common/GetTemplateListDdl';
      static   TEMPLATEADD = UrlConstants.MBS_APISMSMASTER +'common/AddUpdateUserTemplate';
      static   TEMPLATECHANGESTATUS = UrlConstants.MBS_APISMSMASTER +'common/TemplateStatusChange';
      static   TEMPLATEBULKADD = UrlConstants.MBS_APISMSMASTER +'common/SmsTemplateBulkUpload';
      // static   TEMPLATELIST = UrlConstants.MBS_APILOCAL +'common/SmsTemplateGet';
      // static   TEMPLATEADD = UrlConstants.MBS_APILOCAL +'common/SmsTemplateAddUpdate';
      // static   TEMPLATECHANGESTATUS = UrlConstants.MBS_APILOCAL +'common/TemplateStatusChange';

      static   GETBLACKLIST = UrlConstants.MBS_APISMSMASTER +'common/GetBlackList';
      static   ADDBLACKLIST = UrlConstants.MBS_APISMSMASTER +'common/AddUpdateBlackList';
      static   DELETEBLACKLIST = UrlConstants.MBS_APISMSMASTER +'common/DeleteBlackList';

      static   USERPRICELIST = UrlConstants.MBS_APISMSMASTER +'common/GetUserPriceList';
      static WALLATELIST= UrlConstants.MBS_APISMSMASTER +'common/getWalletList';

      static   SMSTRANSACTIONALROUTETYPE = UrlConstants.MBS_APISMSMASTER +'common/getsmstramsactonalroutetype';
      static   SMSROUTE = UrlConstants.MBS_APISMSMASTER +'common/getsmsroute';
      static   SMSCONFIGBYACCOUNTANDSMSTYPE = UrlConstants.MBS_APISMSMASTER +'smsconfig/getbyaccountandroutetype';
      static   SMSCONFIGADD = UrlConstants.MBS_APISMSMASTER +'smsconfig/add';
      static  SMSBALANCEBYACCUNTAMDSERVICETYPE = UrlConstants.MBS_APISMSMASTER + 'common/smsbalance';
      static  SMSBALANCEADD =  UrlConstants.MBS_APISMSMASTER + 'common/addsmsbalance';
      
 
       static   SENDSMSOMCHECK = UrlConstants.MBS_APISMSMASTER +'SendSMS/sendom';
       static  SENDSMSOM = UrlConstants.MBS_APITRANSACTION +'SendSMS/sendom';
       static  SENDSMSMMCHECK = UrlConstants.MBS_APISMSMASTER +'SendSMS/sendmm';
       static  SENDSMSMM =UrlConstants.MBS_APITRANSACTION + 'SendSMS/sendmm';

       static URLCAMAPAIGNREPORT=UrlConstants.MBS_APITRANSACTION + 'SmsMis/GetCampaignMIS';
       static URLDASHBOARDREPORT=UrlConstants.MBS_APITRANSACTION + 'SmsMis/getDashboarddata';

       static URLMESSAGEREPORT=UrlConstants.MBS_APITRANSACTION + 'SmsMis/GetMessageMIS';

       static URLREPLYREPORT=UrlConstants.MBS_APITRANSACTION + 'SmsMis/GetReplyMIS';

       static FUNDADD=UrlConstants.MBS_APITRANSACTION + 'BitCoin/bitreciever';
       static FUNDADDPAYPAL=UrlConstants.MBS_APITRANSACTION + 'PayapalPayment/createPayment';

       static EMAILVERIFICATIONCODE=UrlConstants.MBS_APITRANSACTION+'user/EmailVerificationCode';
       static PHONEVERIFICATIONCODE=UrlConstants.MBS_APITRANSACTION+'user/PhoneVerificationCode';
       static VERIFYEMAILOTP=UrlConstants.MBS_APITRANSACTION+'user/VerifyEmailOTP';
       static VERIFYMOBILEOTP=UrlConstants.MBS_APITRANSACTION+'user/VerifyPhoneOTP';
       static GENERATEAPIKEY=UrlConstants.MBS_APITRANSACTION + 'ApplicationKeys/GenerateKeys';
       static APIKEYDETAIL=UrlConstants.MBS_APITRANSACTION + 'ApplicationKeys/GetAPIKeysDetails';
       static APIKEYDeactivate=UrlConstants.MBS_APITRANSACTION + 'ApplicationKeys/DeactivateService';
       static APIKEYActivate=UrlConstants.MBS_APITRANSACTION + 'ApplicationKeys/ReActivateService';


       static GETMASKINGBYID=UrlConstants.MBS_APISMSMASTER +'common/getmaskingbyid';
       static GETTEMPLATEBYID=UrlConstants.MBS_APISMSMASTER +'common/getTemplatebyid';


       static GETCAMPIGNSCHEDULEDREPORT=UrlConstants.MBS_APISMSMASTER +'common/getCampaignDeliveryReport';
       static GETCAMPAIGNDETAILS=UrlConstants.MBS_APISMSMASTER +'common/getCampaignDetails';
       static GETDELIVERYREPORT=UrlConstants.MBS_APISMSMASTER +'common/getDeliveryReport';
       static GETDELIVERYREPORTDETAILS=UrlConstants.MBS_APISMSMASTER +'common/getDeliveryReportDetails';


       static ACCOUNTCRDRREPORT=UrlConstants.MBS_APISMSMASTER +'common/GetPeriodicReport';

       static SMSCREPORTGET=UrlConstants.MBS_APISMSMASTER +'common/GetSmscReport';


       static ADDUPDATEWHITELIST=UrlConstants.MBS_APISMSMASTER +'common/AddUpdateWhitelist';
       static GETWHITELISTDATA=UrlConstants.MBS_APISMSMASTER +'common/GetWhiteListing';


       static GETGATEWAYPROVIDERLIST=UrlConstants.MBS_APISMSMASTER +'user/getGatewayProviderList';
       static ADDBULKGATEWAYTRANSFER=UrlConstants.MBS_APISMSMASTER +'user/BulkGatewayTransfer';
       static GETUSERGATEWAYLIST=UrlConstants.MBS_APISMSMASTER +'user/GetUserGatewayList';


       static GETACCOUNTHIERARCHY=UrlConstants.MBS_APISMSMASTER +'common/GetAccountHierarchy';
       static GETUSERDETAILSASUSERIS=UrlConstants.MBS_APISMSMASTER +'common/GetUserDetailsAsUserId';

       static GETUSERSCHEDULEDREPORT = UrlConstants.MBS_APISMSMASTER + 'common/GetScheduledReport'
       static SCHEDULEDCAMPAIGNCANCEL = UrlConstants.MBS_APISMSMASTER + 'common/CampaignScheduleCancel';

       static GETDELIVERYRPTDCRYPT = UrlConstants.MBS_APISMSMASTER + 'common/getDeliveryDetailsAsMsgId';

       static USERPROFILEUPDATE =  UrlConstants.MBS_APIACCOUNTMASTER + 'user/UserProfileUpdate';
       static FORGOTPASSWORD =  UrlConstants.MBS_APIACCOUNTMASTER + 'user/SendLoginCredMail';
       static UPLOADUSERLOGO =  UrlConstants.MBS_APIACCOUNTMASTER + 'user/UserLogoUpload';

       static GETUSERDLRREPORTLIST =  UrlConstants.MBS_APIACCOUNTMASTER + 'common/GetUserDlrList'; 
       static GETUSERDLRFILE =  UrlConstants.MBS_APIACCOUNTMASTER + 'common/DownloadDlrFile'; 
       static GENERATEUSERDLR =  UrlConstants.MBS_APIACCOUNTMASTER + 'common/deliveryReportFileGenerate'; 
      
      }


